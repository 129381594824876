.page-contact-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    background: url("../assets/banner/bg3.jpg") center no-repeat;
    background-size: cover;
    color: white;
    .text {
        filter: drop-shadow(1px 1px 0px #2a2a2a);
    }
}

.page-contact-list {
    justify-content: center;
    padding: 100px 0;
    .page-contact-list-item-wrapper {
        padding: 0 10px 20px;
        height: 100%;
    }

    .page-contact-list-item-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 5%);
        padding: 50px 30px;
        background-color: white;
        height: 100%;
        text-align: center;
    }

    .page-contact-list-item-box-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background: var(--color-secondary);
        border-radius: 5px;
        color: white;
        font-size: 2em;
    }

    .page-contact-list-item-box-title {
        padding-top: 20px;
        padding-bottom: 15px;
        color: var(--color-secondary);
    }

    .page-contact-list-item-box-description {
        padding-top: 5px;
        color: #696969;
    }

    .page-contact-map {
        .map {
            width: 100%;
            border: 0;
        }
    }
}
