:root {
    --container: 1200px;
    --color-secondary: #222429;
}

._container {
    max-width: var(--container);
    margin: auto;
    padding: 0 40px;

    @media screen and (max-width: 480px) {
        padding: 0 20px;
    }
}
